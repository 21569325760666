import * as Yup from "yup"

import ContactWrapper, {
  CheckBoxes,
  ContactFromWrapper,
  ContentWrapper,
  StyledErrorMessage,
  StyledSelect,
} from "./contact.style"
import { Field, Form, Formik, useField } from "formik"
import React, { useState } from "react"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import Heading from "../../components/Heading"
import PropTypes from "prop-types"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" })
  return (
    <>
      <label className="checkbox">
        <input {...field} {...props} type="checkbox" />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  )
}

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  )
}

const MySelect = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props)
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <StyledSelect {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  )
}

const StyledLabel = styled.label`
  margin-top: 1rem;
  color: red;
`

const Contact = ({ formTitle, landingPage }) => {
  const [msgSent, setMsgSent] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const trackButtonEvent = () => {
    trackCustomEvent({
      category: "'Contact us",
      action: "submit",
      label: "Contact us form",
    })
  }

  return (
    <ContactWrapper landingPage={landingPage}>
      <Container width="1317px">
        <Box>
          <ContactFromWrapper>
            {!msgSent ? (
              <Fade up delay={100}>
                <Heading
                  content={landingPage ? "Get the offer" : "Leave us a message"}
                  {...formTitle}
                />
                <div className="working-hours">Working hours: 9am - 9pm</div>
              </Fade>
            ) : (
              <Fade up delay={100}>
                <Heading
                  content="Your form has been submitted succesfully"
                  {...formTitle}
                />
              </Fade>
            )}
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "", // added for our select
                messageText: "",
                acceptedTerms: false,
              }}
              validationSchema={Yup.object({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                phone: Yup.string().required("Required"),
                acceptedTerms: Yup.boolean()
                  .required("Required")
                  .oneOf([true], "You must accept the terms and conditions."),
                email: Yup.string()
                  .email("Invalid email addresss")
                  .required("Required"),
              })}
              onSubmit={(values, actions) => {
                fetch("/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  body: encode({ "form-name": "contact", ...values }),
                })
                  .then(() => {
                    setMsgSent(true)
                    // alert("Success")
                    actions.resetForm()
                  })
                  .catch(() => {
                    alert("Error")
                  })
                  .finally(() => actions.setSubmitting(false))
              }}
            >
              <Form name="contact" data-netlify={true}>
                <ContentWrapper>
                  <Fade bottom>
                    <MyTextInput
                      label="First name *"
                      name="firstName"
                      type="text"
                    />
                  </Fade>
                  <Fade bottom>
                    <MyTextInput
                      label="Last name *"
                      name="lastName"
                      type="text"
                    />
                  </Fade>
                  <Fade bottom>
                    <MyTextInput label="Email *" name="email" type="email" />
                  </Fade>
                  <Fade bottom>
                    <MyTextInput
                      label="Phone number *"
                      name="phone"
                      type="text"
                    />
                  </Fade>
                </ContentWrapper>
                <Fade bottom>
                  <div>
                    <label htmlFor="messageText">How can we help?</label>
                    <Field
                      label="How can we help?"
                      name="messageText"
                      component="textarea"
                      rows="10"
                    />
                  </div>
                </Fade>
                <Fade bottom>
                  <CheckBoxes>
                    <MyCheckbox name="newsOffers">
                      I would like to be kept up to date with news and offers
                    </MyCheckbox>
                    <MyCheckbox name="acceptedTerms">
                      I have read and I accept the privacy policy and I give my
                      consent for the information above to be collected.
                    </MyCheckbox>
                  </CheckBoxes>
                </Fade>
                <Fade bottom>
                  <div>
                    <button onClick={trackButtonEvent} type="submit">
                      Submit form
                    </button>
                  </div>
                </Fade>
              </Form>
            </Formik>
          </ContactFromWrapper>
        </Box>
      </Container>
    </ContactWrapper>
  )
}

// PricesAndOffers style props
Contact.propTypes = {
  formTitle: PropTypes.object,
}

Contact.defaultProps = {
  // form title default style
  formTitle: {
    textAlign: "center",
    fontSize: ["28px", "40px"],
    fontWeight: "100",
    letterSpacing: "1px",
    mb: "60px",
    color: "#333333",
  },
}

export default Contact
