import styled from "styled-components"

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    gap: 20px;
  }
`

export const VideoList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  gap: 8px;
  @media only screen and (max-width: 720px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`

export const VideoItem = styled.li`
  background-color: #886638;
  border: 1px solid #886638;
  border-radius: 5px;
  color: white;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: #886638;
    background-color: white;
  }
`

export const Player = styled.video`
  width: 100%;
  max-width: 500px;
  border: 1px solid #ccc;
`
