import {
  Player,
  VideoContainer,
  VideoItem,
  VideoList,
} from "./videosPlayer.style"
import React, { useState } from "react"

import Video from "../../common/assets/videos/skin-analysis_gs.mp4"
import VideoA from "../../common/assets/videos/skin-analysis_tt.mp4"
import VideoB from "../../common/assets/videos/skin-analysis_uv.mp4"
import VideoC from "../../common/assets/videos/skin-analysis_WD.mp4"
import VideoD from "../../common/assets/videos/ghost-function.mp4"
import VideoE from "../../common/assets/videos/facenote.mp4"
import VideoF from "../../common/assets/videos/compare-function.mp4"
import VideoG from "../../common/assets/videos/before-and-after-comparison.mp4"

const VideoPlayer = () => {
  const videos = [
    {
      title: "Skin Analysis GS",
      src: Video,
    },
    { title: "Skin Analysis TT", src: VideoA },
    { title: "Skin Analysis UV", src: VideoB },
    { title: "Skin Analysis WD", src: VideoC },
    { title: "Ghost Function", src: VideoD },
    { title: "Facenote", src: VideoE },
    { title: "Compare Function", src: VideoF },
    { title: "Before & After", src: VideoG },
    // ... add more videos as needed
  ]

  const [currentVideo, setCurrentVideo] = useState(videos[0].src)

  return (
    <VideoContainer>
      <Player controls autoPlay src={currentVideo} />
      <VideoList>
        {videos.map((video, index) => (
          <VideoItem key={index} onClick={() => setCurrentVideo(video.src)}>
            {video.title}
          </VideoItem>
        ))}
      </VideoList>
    </VideoContainer>
  )
}

export default VideoPlayer
