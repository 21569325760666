import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const HGroup = styled.hgroup`
  margin-bottom: ${props => props.mb ?? "50px"};
  @media (max-width: 480px) {
    margin-bottom: ${props => props.mb ?? "30px"};
  }
  text-align: ${props => props.textAlign ?? "center"};
  h2 {
    color: ${themeGet("colors.tertiary")};
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    margin: 0;
    @media (max-width: 1440px) {
      line-height: 2;
    }
  }
  .heading {
    color: ${themeGet("colors.textColor")};
    font-weight: 500;
    font-size: ${props => (props.fontSize ? "32px" : "38px")};
    line-height: 42px;
    letter-spacing: 1.28px;
    margin: 0 auto;
    max-width: 672px;
    @media (max-width: 480px) {
      font-size: 32px;
      line-height: 34px;
    }
  }

  .seperator {
    letter-spacing: -0.14rem;
    color: ${themeGet("colors.textColor", "#fff")};
    margin-top: 24px;
  }
`

export default HGroup
