import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const TreatmentsIntroWrapper = styled.section`
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  padding: ${props =>
    props.category === "conditions" ? "50px 0 0px" : "0px 0 0px"};
  @media (max-width: 990px) {
    padding: ${props =>
      props.category === "conditions" ? "0px 0 0px" : "0px 0 0px"};
  }

  .intro {
    color: ${themeGet("colors.textColor", "#333333")};
    line-height: 28px;
    margin: 0 auto;
    max-width: 826px;
    font-size: 16px;
    margin: 40px auto 40px auto;
    text-align: center;
    @media (max-width: 767px) {
      margin: 24px auto 24px auto;
    }
  }
`

export const ButtonGroup = styled.div`
  margin-bottom: 120px;
  @media only screen and (max-width: 991px) {
    margin-bottom: 56px;
  }
  .reusecore__button {
    text-transform: inherit;
    color: ${themeGet("colors.white", "#fff")};
    background-color: ${themeGet("colors.secondaryBotton", "#886638")};
    border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
    &:hover {
      background-color: #fbfbf9;
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      color: ${themeGet("colors.secondaryBotton", "#886638")};
    }
  }
`

export const VideoContainer = styled.div`
  margin-top: 120px;

  @media only screen and (max-width: 991px) {
    margin-top: 56px;
  }
`

export default TreatmentsIntroWrapper
