import Fade from "react-reveal/Fade"
import React from "react"

import HGroup from "./sectionHeading.style"
import Heading from "../../components/Heading"

const SectionHeading = ({ title, slogan, fontSize, as, ...props }) => {
  return (
    <HGroup {...props} fontSize={fontSize}>
      <Fade up delay={200}>
        <Heading as={as || "h2"} content={slogan} className="heading" />
        <Heading as={as || "h2"} content={title} className="heading" />
        <div className="seperator">————--------</div>
      </Fade>
    </HGroup>
  )
}

export default SectionHeading
