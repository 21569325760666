import SectionWrapper, { BenefitWrapper, Break } from "./benefits.style"

import { BLOCKS } from "@contentful/rich-text-types"
import BenefitsBlock from "../../components/BenefitsBlock"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import Heading from "../../components/Heading"
/* eslint-disable react/display-name */
import PropTypes from "prop-types"
import React from "react"
import SectionHeading from "../../components/SectionHeading"
import Text from "../../components/Text"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Benefits = ({ treatmentBenefits, treatmentBenefitsTitle }) => {
  const treatmentBenefitsLength = treatmentBenefits?.length

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={node.data.target.fields.file["en-US"].url} alt="blogpost" />
      ),
    },
  }
  return (
    <>
      {treatmentBenefits === null && treatmentBenefitsTitle === null ? (
        <Break />
      ) : (
        <SectionWrapper>
          <Container width="1340px">
            <Fade up delay={100}>
              <SectionHeading mb="20px" title={treatmentBenefitsTitle} />
            </Fade>

            <BenefitWrapper treatmentBenefitsLength={treatmentBenefitsLength}>
              {treatmentBenefits.map((benefit, index) => (
                <BenefitsBlock
                  key={index}
                  title={<Heading as="h4" content={benefit.title} />}
                  description={
                    <Fade up delay={200}>
                      <React.Fragment>
                        {documentToReactComponents(
                          benefit.description.json,
                          options
                        )}
                      </React.Fragment>
                    </Fade>
                  }
                  className="ultimateBenefit"
                />
              ))}
            </BenefitWrapper>
          </Container>
        </SectionWrapper>
      )}
    </>
  )
}

Benefits.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  treatmentBenefitsTitle: PropTypes.string,
  treatmentBenefits: PropTypes.array,
}

Benefits.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: ["-30px", "-30px", "-30px", "-25px", "-30px"],
    mr: ["-30px", "-30px", "-30px", "-25px", "-45px"],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
}

export default Benefits
