import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  padding: 120px 0 120px 0;
  overflow: hidden;
  @media only screen and (max-width: 1440px) {
    padding: 120px 0;
  }
  @media only screen and (max-width: 1360px) {
    padding: 120px 0;
  }
  @media (max-width: 990px) {
    padding: 80px 0;
  }
  @media (max-width: 767px) {
    padding: 56px 0 56px 0;
  }
  @media (max-width: 480px) {
    padding: 56px 0 56px 0;
  }
`

export const Break = styled.div`
  margin: 120px 0;
  @media (max-width: 990px) {
    margin: 80px 0;
  }
  @media (max-width: 480px) {
    margin: 60px 0;
  }
`

export const BenefitWrapper = styled.div`
  display: flex;
  justify-content: ${props =>
    props.treatmentBenefitsLength === 1 ? "center" : "space-between"};
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    justify-content: space-between;
  }
  .ultimateBenefit {
    text-align: center;
    margin-top: 120px;
    width: calc(33.3333% - 100px);
    @media only screen and (max-width: 1440px) {
      width: calc(33.3333% - 50px);
      margin-top: 50px;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 624px) {
      width: 100%;
    }
    @media screen and (max-width: 480px) {
      margin: 24px 0;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      margin-top: 50px;
    }
    h4 {
      font-size: 32px;
      font-weight: 100;
      letter-spacing: 1.28px;
      color: ${themeGet("colors.textColor", "#333333")};
    }
    p {
      color: ${themeGet("colors.textColor")};
      margin-bottom: 16px;
      line-height: 28px;
      @media screen and (max-width: 991px) {
        max-width: 700px;
        text-align: center;
        margin: 0 auto 16px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    span {
      letter-spacing: -0.14rem;
      color: ${themeGet("colors.textColor", "#333333")};
    }
  }
`

export default SectionWrapper
