import TreatmentsIntroWrapper, {
  ButtonGroup,
  VideoContainer,
} from "./treatmentsIntro.style"

import Box from "../../components/Box"
import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import Text from "../../components/Text"
import VideoPlayer from "../VideoPlayer"

const TreatmentsIntro = ({
  category,
  imageWrapper,
  treatmentDescription,
  treatmentImage,
  title,
  getTheOffer,
  location,
}) => {
  return (
    <TreatmentsIntroWrapper category={category}>
      <Container width="1805px">
        <Fade up delay={100}>
          <Text
            className="intro"
            content={
              treatmentDescription.treatmentDescription ||
              treatmentDescription.description
            }
          />
        </Fade>
        <Fade up delay={200}>
          <ButtonGroup>
            <a
              href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                title={getTheOffer ? "Get the offer" : "Book a treatment"}
              />
            </a>
          </ButtonGroup>
        </Fade>
        <Fade up delay={300}>
          <Box {...imageWrapper}>
            <div style={{ maxWidth: "1100px", margin: "auto" }}>
              <Image
                fluid={
                  (treatmentImage !== null) | undefined
                    ? treatmentImage.fluid
                    : {}
                }
                alt={title}
              />
            </div>
          </Box>
        </Fade>
        {location?.pathname === "/skin/observ-520x-skin-scanner/" && (
          <Fade up delay={300}>
            <VideoContainer>
              <VideoPlayer />
            </VideoContainer>
          </Fade>
        )}
      </Container>
    </TreatmentsIntroWrapper>
  )
}

// MainImage style props
TreatmentsIntro.propTypes = {
  imageWrapper: PropTypes.object,
  treatmentDescription: PropTypes.object,
  treatmentImage: PropTypes.object,
  title: PropTypes.string,
}

// MainImage default style
TreatmentsIntro.defaultProps = {
  imageWrapper: {
    display: " block",
  },
}

export default TreatmentsIntro
