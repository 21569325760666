import _ from "lodash"
import React from "react"
import { Modal } from "@redq/reuse-modal"
import { graphql } from "gatsby"

import Benefits from "../containers/Benefits"
import ChangeYourLife from "../containers/ChangeYourLife"
import Contact from "../containers/Contact"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import TreatmentsIntro from "../containers/TreatmentsIntro"
import { ContentWrapper } from "../containers/elegant.style"

const LandingPageTemplate = ({ data: { contentfulLandingPage }, location }) => {
  const {
    slug,
    description,
    image,
    title,
    extraDetails,
    benefitsTitle,
    benefits,
    seoDetails,
  } = contentfulLandingPage

  const url = location.href ? location.href : ""

  return (
    <>
      <SEO
        url={url}
        image={`https:${image.fluid.src}`}
        title={`${seoDetails.metaTitle}`}
        shortDesc={`${seoDetails.metaShortDescription}`}
        description={`${seoDetails.metaDescription}`}
      />
      <Layout>
        <Modal />
        <ContentWrapper>
          <PageTitle title={`${title}`} />
          <TreatmentsIntro
            getTheOffer
            treatmentDescription={description}
            treatmentImage={image}
            title={title}
          />
          <Benefits
            treatmentBenefitsTitle={benefitsTitle}
            treatmentBenefits={benefits}
          />
          <Contact landingPage />

          <ChangeYourLife
            title="Ready to start your journey with Elegant?"
            text="We’re here to help you feel your best. Explore our services, get in touch and book your consultation today."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default LandingPageTemplate

export const pageQuery = graphql`
  query LandingPageBySlug($slug: String!) {
    contentfulLandingPage(slug: { eq: $slug }) {
      title
      benefitsTitle
      seoDetails {
        metaTitle
        metaShortDescription
        metaDescription
      }
      benefits {
        title
        description {
          json
        }
      }

      description {
        description
      }
      extraDetails {
        description {
          json
        }
        title
      }

      image {
        fluid(quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      slug
    }
  }
`
